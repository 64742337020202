<template>
  <van-empty :description="description" :image="require('@/assets/images/load-error.png')">
    <van-button type="primary" class="bottom-button" @click="reatyClickHandle">重试</van-button>
  </van-empty>
</template>

<script>
export default {
  name: 'LoadError',
  props: {
    description: {
      type: String,
      default: '数据加载异常'
    }
  },
  methods: {
    reatyClickHandle() {
      this.$emit('reaty')
    }
  }
}
</script>

<style lang="scss" scoped>
  .bottom-button {
    width: 160px;
    height: 40px;
  }
</style>